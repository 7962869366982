// extracted by mini-css-extract-plugin
export var button = "ContactForm-module--button--f3751";
export var confirmationContainer = "ContactForm-module--confirmation-container--d88e7";
export var confirmationLink = "ContactForm-module--confirmation-link--cca87";
export var content = "ContactForm-module--content--c9d46";
export var form = "ContactForm-module--form--80d47";
export var formInput = "ContactForm-module--form-input--0c24b";
export var formTextarea = "ContactForm-module--form-textarea--a47ff";
export var formWrapper = "ContactForm-module--formWrapper--1bb7f";
export var heading = "ContactForm-module--heading--4c1a8";
export var info = "ContactForm-module--info--0b515";
export var list = "ContactForm-module--list--7e512";
export var loader = "ContactForm-module--loader--661a9";
export var loaderContainer = "ContactForm-module--loader-container--d8e78";
export var spin = "ContactForm-module--spin--afeb3";
export var text = "ContactForm-module--text--86602";
export var underheading = "ContactForm-module--underheading--a022d";
export var wrapper = "ContactForm-module--wrapper--7a8fe";