import React, {useState} from 'react';
import * as style from './ContactForm.module.scss';
import {StaticQuery, Link, graphql} from 'gatsby';
import { API } from 'aws-amplify';

const ContactForm = () => {
	const [statusMessage, setStatusMessage] = useState('');
  const [loading, setLoading] = useState(false);
	const [email, setEmail] = useState('');
	const [subject, setSubject] = useState('');
	const [message, setMessage] = useState('');

	const submitHandler = async (e) => {
			 e.preventDefault();
			 setLoading(true);
			 try {
				 const parameters = {
					 body: {
						 email,
						 subject,
						 message
					 }
				 }

				 await API.post('paulsenregnskabAPI', '/sendEmail', parameters);
				 console.log('awaiting API');
				 setStatusMessage(`Din besked er modtaget! Vi vender tilbage til dig hurtigst muligt.`);

			 } catch(error){
				 setStatusMessage(`Vi kunne ikke tage imod din besked på dette tidspunkt! Prøv igen senere.`);
			 }

			 setLoading(false);
 }

	 return(
		 <div className={style.wrapper}>
		 	<div className={style.content}>
				<h2 className={style.heading}>Kontakt Os</h2>
				<div className={style.info}>
					<div className={style.text}>
						<h3 className={style.underheading}>Vi vil meget gerne høre fra dig</h3>
						<p>
							Send en besked til os, så vender vi tilbage til dig hurtigst muligt.
						</p>
						<StaticQuery query={graphql`
							{
								general: allWpInfo(filter: { title: {eq: "General Info"}})  {
							    edges {
							      node{
							        id
							        title
							        generalInfo {
							          address
							          email
							          phone
							        }
							      }
							    }
							  }
							}
						`} render={props => (
								props.general.edges.map(info => (
									<ul className={style.list}>
										<li>
											{info.node.generalInfo.address}
										</li>
										<li>
											{info.node.generalInfo.email}
										</li>
										<li>
											Telefon: {info.node.generalInfo.phone}
										</li>
									</ul>
								))
						)} />

					</div>
					<div className={style.formWrapper}>
					{
						loading ?
							<div className={style.loaderContainer}>
								<div className={style.loader}></div>
							</div>


			        : statusMessage ?

							<div className={style.confirmationContainer}>
			            <h3>Tak for at kontakte os!</h3>
			            <p>{statusMessage}</p>
									<Link to='/' className={style.confirmationLink}>Tilbage til hjem</Link>
			          </div>

			        :

						<form className={style.form} onSubmit={submitHandler}>
							<input type='email' className={style.formInput} placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)} />
							<input type='text' className={style.formInput} placeholder='Emne' value={subject} onChange={(e) => setSubject(e.target.value)}/>
							<textarea className={style.formTextarea} placeholder='Din besked' value={message} onChange={(e) => setMessage(e.target.value)}/>
							<button type='submit' className={style.button}>Send Besked</button>
						</form>
					}
					</div>
				</div>
			</div>
		 </div>
	 )
}
export default ContactForm;
